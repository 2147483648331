/* Your existing CSS */
/* Import Font Awesome styles */
@import '~@fortawesome/fontawesome-free/css/all.min.css';

.table-container {
  margin-top: 120px;
  overflow-x: auto; /* Enable horizontal scrolling for small screens */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #f0e7e7;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #20126F;
  border-radius: 5px;
  color: white;
 
}

.pagination-container {
 margin-bottom: 20px;
  align-items: center;
}


/* Responsive styles for small screens */
@media screen and (max-width: 600px) {

  .table-container {
    margin-top: 80px;
    overflow-x: auto; /* Enable horizontal scrolling for small screens */
  }

  th,
  td {
   
    width: 100%;
    box-sizing: border-box;
  }

  th,
  td {
    text-align: center;
  }

  /* Optional: Add styling for header cells */
  th {
    background-color: #20126F;
  }
}

.pagination-container {
  margin-top: 20px;

  align-items: center;
}

/* Your existing CSS */
